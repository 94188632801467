import React, { Suspense, useEffect, useState } from "react";
import {
  COLUMN_CODE,
  TableManger,
  TABLES,
  TABLE_DISPLAY_NAME
} from "../managers/TableManger";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  fetchAuditLogs,
  recordsByTableNameSelector,
  updateAudiLogs
} from "../redux/slices/recordSlice";
import { DEFAULT_PAGE_SIZE } from "../services/auditLog";
import SideBarService from "../services/sidebar";
import {
  DKDataGrid,
  DKSpinner,
  DKIcons,
  DKIcon,
  DKLabel,
  DKSearchBar,
  DKListPicker2,
  DKButton,
  DKDateRangePicker,
  INPUT_TYPE,
  getDateAsString
} from "deskera-ui-library";
import ic_no_data from "../../src/assets/icons/ic_no_data_3.png";
import {
  AUDIT_ACTIONS_FILTER,
  AUDIT_ACTIONS_TITLE,
  AUDIT_BULK_OBJECT_TYPE,
  AUDIT_LOG_EMPTY_DISPLAY_VALUE,
  AUDIT_OBJECT_TYPE,
  DATE_RNGE_FILTER_OPTION,
  NOTIFICATION_ACTION_TYPE,
  OBJECT_TYPE,
  QUOTE_AUDIT_LOG_FIELDS,
  TICKET_FIELD_KEYS_WITH_DETAIL,
  TICKET_FIELD_VISIBLE
} from "../constants/Enum";
import Utility, { getCapitalized, trimString } from "../utility/Utility";
import { INVITE_USER_STATUS, PERMISSIONS } from "../constants/Permission";
import { useDebounce } from "../utility/Debounce";
import { Renderers } from "../Helper/Renderers";
import { AccountService } from "../services/accounts";
import {
  ACTIVITY_DATE_FORMAT,
  DETAIL_PAGE_LEFT_PANEL_WIDTH,
  LOG_SCROLL_CONTAINER_ID,
  TICKET_FIELDS
} from "../constants/Constant";
import {
  isMobileAppWebView,
  isViewportMobile
} from "../utility/GetViewportSize";
import IUser, { getFullName, getUserByUserId } from "../model/User";
import {
  convertCRMDateFormatToUILibraryFormat,
  DateUtil
} from "../utility/Date";
import BooksService from "../services/books";
import { AUDIT_LOG_COLUMNS, AuditHelper } from "../Helper/AuditHelper";
import { getTeams } from "../redux/slices/tenantSlice";

export default function AuditLogs(props) {
  /*
state and other declarations goes here
*/
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  let auditLogData = useAppSelector(
    recordsByTableNameSelector(TABLES.AUDIT_LOG)
  );
  const [columns, setColumns] = React.useState([]);
  const [selectedDayFilter, setSelectedDayFilter] = useState(
    DATE_RNGE_FILTER_OPTION.THIS_WEEK
  );
  const [showAllModules, setShowAllModules] = useState(false);
  const [showAllActions, setShowAllActions] = useState(false);
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [showHideCalendar, setShowHideCalendar] = useState(false);
  const financialDates = BooksService.getFinancialStartEndDate();
  const [filter, setFilter] = useState({
    moduleName: null,
    actionType: null,
    users: null,
    fromDate: financialDates?.financialStartDate,
    toDate: financialDates?.financialEndDate
  });
  const [searchText, setSearchText] = useState("");
  const debouncedSearchTerm = useDebounce(searchText, 300);
  const users: IUser[] = useAppSelector((state) => state.tenant.users);
  const [filterDates, setFilterDates] = useState<any>(
    BooksService.getFinancialStartEndDate()
  );
  const teams = useAppSelector(getTeams());

  /*
   all the effect goes here
   */

  const getFilterParams = () => {
    const filterParams = {};

    if (filter.moduleName?.id) {
      filterParams["objectType"] = filter.moduleName.id;
    }

    if (filter.actionType?.id) {
      filterParams["action"] = filter.actionType.id;
    }

    if (filter.users?.id) {
      filterParams["actionBy"] = filter.users.id;
    }

    if (filter.fromDate) {
      filterParams["fromDate"] = filter.fromDate;
    }
    if (filter.toDate) {
      filterParams["toDate"] = filter.toDate;
    }
    return filterParams;
  };

  const getAuditLogs = (page) => {
    if (
      props.module === TABLES.ACCOUNT &&
      !Utility.isEmptyObject(props.refRecordsIds)
    ) {
      AccountService.fetchAuditLogsForAccount(props.refRecordsIds, {
        pageSize: DEFAULT_PAGE_SIZE,
        pageNo: page,
        search: debouncedSearchTerm,
        ...getFilterParams()
      })
        .then((response) => {
          dispatch(updateAudiLogs(response));
        })
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      dispatch(
        fetchAuditLogs({
          params: {
            recordId: props?.recordId,
            pageSize: DEFAULT_PAGE_SIZE,
            pageNo: page,
            ...getFilterParams()
          },
          searchText: debouncedSearchTerm
        })
      ).finally(() => {
        setLoading(false);
      });
    }
  };
  useEffect(() => {
    if (Utility.isEmptyObject(props.recordId)) {
      // RouteManager.setPresenter({ props });
    }
    getColumnConfig();
  }, []);
  useEffect(() => {
    setLoading(true);
    getAuditLogs(1);
  }, [debouncedSearchTerm, filter, selectedDayFilter, props?.recordId]);

  const onSearch = (text) => {
    setSearchText(text);
  };
  const getColumnConfig = () => {
    let columns = Utility.deepCloneObject(AUDIT_LOG_COLUMNS);
    columns = columns?.map((column) => {
      column.key = column.columnCode;
      switch (column.columnCode) {
        case COLUMN_CODE.AUDIT_LOG.ACTION_DATE:
          column.renderer = Renderers.actionDateRenderer;
          break;
        case COLUMN_CODE.AUDIT_LOG.ACTION_BY:
          column.type = INPUT_TYPE.SELECT;
          column.renderer = Renderers.userRenderer;
          break;
        case COLUMN_CODE.AUDIT_LOG.ACTIONS:
          column.renderer = Renderers.actionTypeRenderer;
          break;
        case COLUMN_CODE.AUDIT_LOG.LOG:
          column.renderer = (data) => logRenderer(data);
          break;
        case COLUMN_CODE.AUDIT_LOG.OLD_VALUES:
          column.renderer = (data) => logRenderer(data, true);
          break;
      }
      return column;
    });
    setColumns(columns);
  };
  const onPageChange = (page) => {
    setLoading(true);
    getAuditLogs(page);
  };
  const getNoDataView = () => {
    return (
      <div
        className="column align-self-center align-items-center position-absolute"
        style={{ top: "30%", pointerEvents: "none" }}
      >
        <DKIcon
          src={ic_no_data}
          className="ic-l"
          style={{ opacity: 0.2, marginTop: 70 }}
        />
        <DKLabel text="No data found" className="fw-m mt-l" />
        <DKLabel
          text="Once data is available, it will appear here"
          className="text-gray mt-s "
        />
      </div>
    );
  };
  const getColumnValue = (columnCode: string, rowData: any) => {
    let columnID = TableManger.getColumnId(
      TABLES[rowData?.objectType],
      columnCode
    );
    if (Utility.isEmptyObject(columnID)) return "";
    return rowData.metaData?.recordsAfter?.[0]?.cells[columnID];
  };

  const findMissingArray = (array1, array2) => {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1.id === object2.id;
      });
    });
  };

  const getColumnRequiredParser = (fieldData, beforeData) => {
    let fieldNameWithModule = [];
    if (Utility.isEmptyObject(fieldData.objectType)) {
      fieldData.forEach((data) => {
        fieldNameWithModule.push({
          [data.objectType]: !beforeData
            ? data.requiredColumnsNew?.map((column) => column.name)
            : data.requiredColumnsOld?.map((column) => column.name)
        });
      });
    }
    return fieldNameWithModule;
  };

  const renderRowForRequiredFields = (fieldData) => {
    const key = Object.keys(fieldData);
    const value = Object.values(fieldData);
    return (
      <div>
        <strong>{getCapitalized(key.toString().toLowerCase())}</strong>:{" "}
        {value?.toString()?.replaceAll(",", ", ")}
      </div>
    );
  };

  const renderTitleAndValue = (metaData) => {
    return metaData?.map((columnsMetaData) => (
      <div style={{ textAlign: "left" }}>
        <b>{columnsMetaData.title}</b>: {columnsMetaData.value}
      </div>
    ));
  };

  const logRenderer = (data, beforeData = false) => {
    let rowData = data.rowData;
    let columnsMetaData =
      rowData.action ===
      NOTIFICATION_ACTION_TYPE.REQUIRED_COLUMN_SETTING_CHANGED
        ? getColumnRequiredParser(
            data.rowData?.metaData?.requiredColumnsByTable,
            beforeData
          )
        : [
              OBJECT_TYPE.QUOTE,
              OBJECT_TYPE.INVOICE,
              OBJECT_TYPE.TICKET,
              "TICKET_NOTE",
              "TICKET_ATTACHMENT",
              OBJECT_TYPE.CUSTOM_FIELD
            ].includes(rowData?.objectType)
          ? []
          : AuditHelper.getTableColumns(rowData?.objectType?.toLowerCase());
    try {
      if (
        !beforeData &&
        [
          NOTIFICATION_ACTION_TYPE.REC_BULK_CREATE,
          NOTIFICATION_ACTION_TYPE.REC_BULK_DELETE,
          NOTIFICATION_ACTION_TYPE.REC_BULK_UPDATE,
          NOTIFICATION_ACTION_TYPE.REC_BULK_CREATE_REJECTED,
          NOTIFICATION_ACTION_TYPE.REC_BULK_UPDATE_REJECTED
        ].includes(rowData.action)
      ) {
        return (
          <div>
            &nbsp;
            <strong>
              {rowData.metaData?.recordCount
                ? rowData.metaData?.recordCount
                : 0}
            </strong>
            &nbsp;
            {AUDIT_BULK_OBJECT_TYPE[rowData.objectType]}&nbsp;
            {AUDIT_ACTIONS_TITLE[rowData.action]}
          </div>
        );
      } else {
        if (
          !beforeData &&
          [
            NOTIFICATION_ACTION_TYPE.REC_CREATE,
            NOTIFICATION_ACTION_TYPE.REC_DELETE,
            NOTIFICATION_ACTION_TYPE.NOTE_CREATE,
            NOTIFICATION_ACTION_TYPE.NOTE_DELETE,
            NOTIFICATION_ACTION_TYPE.ATTACHMENT_CREATE,
            NOTIFICATION_ACTION_TYPE.ATTACHMENT_DELETE,
            NOTIFICATION_ACTION_TYPE.REC_CREATE_REJECTED,
            NOTIFICATION_ACTION_TYPE.REC_UPDATE_REJECTED,
            NOTIFICATION_ACTION_TYPE.REC_UPDATE_REJECTED,
            NOTIFICATION_ACTION_TYPE.SUPPORT_EMAIL_CREATE,
            NOTIFICATION_ACTION_TYPE.SUPPORT_EMAIL_DELETE
          ].includes(rowData.action)
        ) {
          switch (rowData.objectType) {
            case OBJECT_TYPE.CONTACT:
            case OBJECT_TYPE.DEAL:
            case OBJECT_TYPE.ACTIVITY:
            case OBJECT_TYPE.CAMPAIGN:
              let objectValues = AuditHelper.getLogRenderForObjectType(
                rowData.objectType,
                rowData,
                columnsMetaData
              );
              return renderTitleAndValue(objectValues);
            case OBJECT_TYPE.TICKET:
              return Renderers.ticketRenderer(rowData);
            case OBJECT_TYPE.QUOTE:
            case OBJECT_TYPE.INVOICE:
              return "";
            default:
              let valDefault = getColumnValue(COLUMN_CODE.DEAL.NAME, rowData);
              return (
                <div style={{ textAlign: "left" }}>
                  <b>Name: </b>
                  {valDefault
                    ? getColumnValue(COLUMN_CODE.DEAL.NAME, rowData)
                    : ""}
                  &nbsp;
                  <br />
                </div>
              );
          }
        } else if (
          rowData.action === NOTIFICATION_ACTION_TYPE.REC_UPDATE &&
          ![
            OBJECT_TYPE.TICKET,
            OBJECT_TYPE.QUOTE,
            OBJECT_TYPE.INVOICE,
            "TICKET_NOTE",
            "TICKET_ATTACHMENT"
          ].includes(rowData.objectType)
        ) {
          let columnsMetaData: any = AuditHelper.getTableColumns(
            rowData.objectType.toLowerCase()
          );
          let arr = [],
            colValue = AUDIT_LOG_EMPTY_DISPLAY_VALUE;
          columnsMetaData?.forEach((column: any) => {
            const recordsAfter =
              rowData.metaData.recordsAfter?.[0]?.cells || {};
            const recordsBefore =
              rowData.metaData.recordsBefore?.[0]?.cells || {};
            switch (column.type) {
              case INPUT_TYPE.TEXT:
              case INPUT_TYPE.PHONE:
              case INPUT_TYPE.EMAIL:
              case INPUT_TYPE.NUMBER:
                if (
                  rowData.metaData.recordsAfter?.[0]?.cells?.[column.id] !==
                  rowData.metaData.recordsBefore?.[0]?.cells?.[column.id]
                ) {
                  arr.push({
                    colName: column.name,
                    colValue: beforeData
                      ? rowData.metaData.recordsBefore?.[0]?.cells[column.id]
                      : rowData.metaData.recordsAfter?.[0]?.cells[column.id]
                  });
                }
                break;
              case INPUT_TYPE.DATE:
                if (
                  rowData.metaData.recordsAfter?.[0]?.cells?.[column.id] !==
                  rowData.metaData.recordsBefore?.[0]?.cells?.[column.id]
                ) {
                  arr.push({
                    colName: column.name,
                    colValue: `${DateUtil.getDateStrFromDate(
                      new Date(
                        beforeData
                          ? rowData.metaData.recordsBefore?.[0]?.cells?.[
                              column.id
                            ]
                          : rowData.metaData.recordsAfter?.[0]?.cells?.[
                              column.id
                            ]
                      ),
                      DateUtil.getOrgDateFormat()
                    )} ${
                      !["Deal Date", "Closing Date"].includes(column.name)
                        ? Utility.getFormattedTime(
                            new Date(
                              beforeData
                                ? rowData.metaData.recordsBefore?.[0]?.cells?.[
                                    column.id
                                  ]
                                : rowData.metaData.recordsAfter?.[0]?.cells?.[
                                    column.id
                                  ]
                            ),
                            true
                          )
                        : ""
                    }`
                  });
                }
                break;
              case INPUT_TYPE.SELECT:
              case INPUT_TYPE.MULTI_SELECT:
                let SelectValBefore =
                  rowData.metaData.recordsBefore?.[0]?.cells?.[column.id] ?? [];
                let SelectValAfter =
                  rowData.metaData.recordsAfter?.[0]?.cells?.[column.id] ?? [];
                let SelectRefValBefore =
                  rowData.metaData.recordsBefore?.[0]?.cells?.[
                    column.id + "_detail"
                  ];
                let SelectRefValAfter =
                  rowData.metaData.recordsAfter?.[0]?.cells?.[
                    column.id + "_detail"
                  ];
                SelectValBefore?.sort();
                SelectValAfter?.sort();
                let SelectIsChanged = false;
                // if (
                //   Object.keys(recordsAfter)?.length !==
                //   Object.keys(recordsBefore)?.length
                // ){
                //   SelectIsChanged = true;}
                for (
                  let i = 0;
                  i < Math.max(SelectValAfter?.length, SelectValBefore?.length);
                  i++
                ) {
                  // if (SelectIsChanged === true) break;
                  // else
                  if (SelectValAfter[i] !== SelectValBefore[i]) {
                    SelectIsChanged = true;
                    break;
                  }
                }
                if (SelectIsChanged) {
                  if (!Utility.isEmptyObject(column?.refTable)) {
                    let refTableColumnId = TableManger.getColumnId(
                      column?.refTable?.objectType?.toLowerCase(),
                      COLUMN_CODE.ACCOUNT.NAME
                    );
                    if (beforeData) {
                      colValue =
                        SelectRefValBefore?.[0]?.cells?.[refTableColumnId] ??
                        AUDIT_LOG_EMPTY_DISPLAY_VALUE;
                    } else {
                      colValue =
                        SelectRefValAfter?.[0]?.cells?.[refTableColumnId] ??
                        AUDIT_LOG_EMPTY_DISPLAY_VALUE;
                    }
                  } else if (beforeData) {
                    if (SelectRefValBefore?.length > 0) {
                      colValue = SelectRefValBefore?.map((val) => {
                        return val?.cells?.["name"] || val?.["name"];
                      }).join(", ");
                    } else {
                      colValue = SelectValBefore?.map((val) => {
                        let option = column.options?.find(
                          (opt) => opt.id === val
                        );
                        return option?.name || AUDIT_LOG_EMPTY_DISPLAY_VALUE;
                      }).join(", ");
                    }
                  } else {
                    if (SelectRefValAfter?.length > 0) {
                      colValue = SelectRefValAfter?.map((val) => {
                        return val?.cells?.["name"] || val?.["name"];
                      }).join(", ");
                    } else {
                      colValue = SelectValAfter?.map((val) => {
                        let option = column.options?.find(
                          (opt) => opt.id === val
                        );
                        return option?.name || AUDIT_LOG_EMPTY_DISPLAY_VALUE;
                      }).join(", ");
                    }
                  }
                  arr.push({
                    colName: column.name,
                    colValue: Utility.isEmptyObject(colValue)
                      ? AUDIT_LOG_EMPTY_DISPLAY_VALUE
                      : colValue
                  });
                }
                break;
              case "ref":
              case INPUT_TYPE.DROPDOWN:
              case "ref_array":
                if (!Utility.isEmptyObject(rowData.metaData?.lookup)) break;
                let refValBefore =
                  rowData.metaData.recordsBefore?.[0]?.cells?.[column.id];
                let refValAfter =
                  rowData.metaData.recordsAfter?.[0]?.cells?.[column.id];
                refValBefore?.sort();
                refValAfter?.sort();
                let refIsChanged = false;
                // if (
                //   Object.keys(recordsAfter).length !==
                //   Object.keys(recordsBefore).length
                // )
                //   refIsChanged = true;
                for (
                  let i = 0;
                  i < Math.max(refValAfter?.length, refValBefore?.length);
                  i++
                ) {
                  // if (refIsChanged === true) break;
                  // else
                  if (refValAfter[i] !== refValBefore[i]) {
                    refIsChanged = true;
                    break;
                  }
                }
                let refObjectType = column?.refTable?.objectType;
                let refTableColumns = AuditHelper.getTableColumns(
                  refObjectType?.toLowerCase()
                );
                let nameColumnFromRefTable = refTableColumns.find(
                  (e) => e.columnCode === "name"
                );
                if (refIsChanged) {
                  if (beforeData) {
                    colValue = refValBefore
                      ?.map((val) => {
                        let refRecord =
                          rowData.metaData?.recordsBefore?.[0]?.cells?.[
                            column.id + "_detail"
                          ]?.find((e) => e._id === val);
                        return refRecord?.cells?.[nameColumnFromRefTable?.id];
                      })
                      .join(", ");
                  } else {
                    colValue = refValAfter
                      ?.map((val) => {
                        let refRecord =
                          rowData.metaData?.recordsAfter?.[0]?.cells?.[
                            column.id + "_detail"
                          ]?.find((e) => e._id === val);
                        return refRecord?.cells?.[nameColumnFromRefTable?.id];
                      })
                      .join(", ");
                  }
                  arr.push({
                    colName: column.name,
                    colValue: Utility.isEmptyObject(colValue)
                      ? AUDIT_LOG_EMPTY_DISPLAY_VALUE
                      : colValue
                  });
                }
                break;
              case "user":
              case "user_array":
                let userValBefore =
                  rowData.metaData.recordsBefore?.[0]?.cells?.[column.id];
                let userValAfter =
                  rowData.metaData.recordsAfter?.[0]?.cells?.[column.id];
                userValBefore?.sort();
                userValAfter?.sort();
                let userIsChanged = false;
                if (
                  Object.keys(recordsAfter).length !==
                  Object.keys(recordsBefore).length
                )
                  userIsChanged = true;
                for (
                  let i = 0;
                  i < Math.max(userValAfter?.length, userValBefore?.length);
                  i++
                ) {
                  if (userIsChanged === true) break;
                  else if (userValAfter[i] !== userValBefore[i]) {
                    userIsChanged = true;
                    break;
                  }
                }
                if (userIsChanged) {
                  if (beforeData) {
                    colValue = userValBefore
                      ?.map((val) => {
                        let userRecord =
                          rowData.metaData?.recordsBefore?.[0]?.cells?.[
                            column.id + "_detail"
                          ]?.find((e) => e.iamUserId == val);
                        return userRecord?.name;
                      })
                      .join(", ");
                  } else {
                    colValue = userValAfter
                      ?.map((val) => {
                        let userRecord =
                          rowData.metaData?.recordsAfter?.[0]?.cells?.[
                            column.id + "_detail"
                          ]?.find((e) => e.iamUserId == val);
                        return userRecord?.name;
                      })
                      .join(", ");
                  }
                  arr.push({ colName: column.name, colValue: colValue });
                }
                break;
              case "number-array":
                if (column.columnCode === COLUMN_CODE.CAMPAIGN.SEGMENT_ID) {
                  let NumberValBefore =
                    rowData.metaData.recordsBefore?.[0]?.cells?.[column.id];
                  let NumberValAfter =
                    rowData.metaData.recordsAfter?.[0]?.cells?.[column.id];
                  NumberValBefore?.sort();
                  NumberValAfter?.sort();
                  let SelectIsChanged = false;
                  if (
                    Object.keys(recordsAfter).length !==
                    Object.keys(recordsBefore).length
                  )
                    SelectIsChanged = true;
                  for (
                    let i = 0;
                    i <
                    Math.max(NumberValAfter?.length, NumberValBefore?.length);
                    i++
                  ) {
                    if (SelectIsChanged === true) break;
                    else if (NumberValAfter[i] !== NumberValBefore[i]) {
                      SelectIsChanged = true;
                      break;
                    }
                  }
                  if (SelectIsChanged) {
                    arr.push({
                      colName: column?.name,
                      colValue: AuditHelper.getSegmentDisplayValue(
                        column.columnCode,
                        columnsMetaData,
                        rowData
                      )
                    });
                  }
                }
                break;
              case "json_array":
                let noteBefore =
                  rowData.metaData.recordsBefore?.[0]?.cells?.[column.id];
                let noteAfter =
                  rowData.metaData.recordsAfter?.[0]?.cells?.[column.id];
                if (noteAfter?.length === noteBefore?.length) {
                  typeof noteAfter !== "string" &&
                    noteAfter?.forEach((note) => {
                      let noteUpdateBefore = noteBefore.find(
                        (item) => item.id === note.id
                      );
                      if (note.updated !== noteUpdateBefore.updated) {
                        arr.push({
                          colName: column?.name,
                          colValue: !beforeData
                            ? typeof note === "string"
                              ? note.substring(0, 23)
                              : trimString(note?.message, 40)
                            : typeof noteUpdateBefore === "string"
                              ? noteUpdateBefore.substring(0, 23)
                              : trimString(noteUpdateBefore?.message, 40)
                        });
                      }
                    });
                }

                if (
                  noteAfter?.length > (noteBefore?.length || 0) &&
                  noteAfter?.length !== (noteBefore?.length || 0)
                ) {
                  arr.push({
                    colName: column?.name,
                    colValue: !beforeData
                      ? typeof noteBefore === "string"
                        ? noteAfter.substring(0, 23)
                        : trimString(noteAfter?.[0]?.message, 40)
                      : ""
                  });
                }

                if (
                  noteAfter?.length < noteBefore?.length &&
                  noteAfter?.length !== noteBefore?.length
                ) {
                  if (typeof noteBefore !== "string") {
                    let deletedNote = findMissingArray(noteBefore, noteAfter);
                    if (deletedNote.length > 0) {
                      arr.push({
                        colName: column?.name,
                        colValue: beforeData
                          ? typeof deletedNote.message === "string"
                            ? deletedNote.message.substring(0, 23)
                            : trimString(deletedNote?.[0]?.message, 40)
                          : ""
                      });
                    }
                  } else {
                    arr.push({
                      colName: column?.name,
                      colValue: beforeData
                        ? typeof noteBefore === "string"
                          ? noteBefore.substring(0, 23)
                          : trimString(noteBefore, 40)
                        : ""
                    });
                  }
                }
            }
          });
          return arr?.map((columnsMetaData, key) => (
            <div style={{ textAlign: "left" }}>
              <b>{columnsMetaData.colName}</b>: {columnsMetaData.colValue}
            </div>
          ));
        } else if (
          rowData.action === NOTIFICATION_ACTION_TYPE.TABLE_PERMISSION_CHANGED
        ) {
          if (rowData.metaData?.userPermissionUpdates?.length !== 0)
            return (
              <div style={{ textAlign: "left" }}>
                Permission for user
                <strong>
                  {rowData.metaData?.userPermissionUpdates[0]?.user?.name}
                </strong>
                changed to&nbsp;
                <strong>
                  {beforeData
                    ? PERMISSIONS[
                        rowData.metaData?.userPermissionUpdates[0]
                          ?.accessLevelBefore
                      ].title
                    : PERMISSIONS[
                        rowData.metaData?.userPermissionUpdates[0]
                          ?.accessLevelAfter
                      ].title}
                </strong>
                &nbsp;
              </div>
            );
          else if (rowData.metaData?.teamPermissionUpdate?.length !== 0)
            return (
              <div style={{ textAlign: "left" }}>
                Permission for team
                <strong>
                  {rowData.metaData?.teamPermissionUpdates[0]?.team?.name}
                </strong>
                changed to&nbsp;
                <strong>
                  {beforeData
                    ? PERMISSIONS[
                        rowData.metaData?.teamPermissionUpdates[0]
                          ?.accessLevelBefore
                      ].title
                    : PERMISSIONS[
                        rowData.metaData?.teamPermissionUpdates[0]
                          ?.accessLevelAfter
                      ].title}
                </strong>
                &nbsp;
              </div>
            );
        } else if (
          rowData.action === NOTIFICATION_ACTION_TYPE.REC_PERMISSION_CHANGED
        ) {
          if (rowData.metaData?.userPermissionUpdates?.length !== 0)
            return (
              <>
                <div style={{ textAlign: "left" }}>
                  <div>
                    Contact
                    <strong>
                      {beforeData
                        ? rowData.metaData.recordsBefore?.[0]?.cells[
                            TableManger.getColumnId(
                              TABLES[rowData.objectType],
                              COLUMN_CODE.DEAL.NAME
                            )
                          ]
                        : rowData.metaData.recordsAfter?.[0]?.cells[
                            TableManger.getColumnId(
                              TABLES[rowData.objectType],
                              COLUMN_CODE.DEAL.NAME
                            )
                          ]}
                    </strong>
                    is updated.
                  </div>
                  <div>
                    Permission for user
                    <strong>
                      {rowData.metaData?.userPermissionUpdates[0]?.user?.name}
                    </strong>
                    changed to&nbsp;
                    <strong>
                      {beforeData
                        ? PERMISSIONS[
                            rowData.metaData?.userPermissionUpdates[0]
                              ?.accessLevelBefore
                          ].title
                        : PERMISSIONS[
                            rowData.metaData?.userPermissionUpdates[0]
                              ?.accessLevelAfter
                          ].title}
                    </strong>
                    &nbsp;
                  </div>
                </div>
              </>
            );
          else if (rowData.metaData?.teamPermissionUpdate?.length !== 0)
            return (
              <>
                <div style={{ textAlign: "left" }}>
                  <div>
                    Contact
                    <strong>
                      {beforeData
                        ? rowData.metaData.recordsBefore?.[0]?.cells[
                            TableManger.getColumnId(
                              TABLES[rowData.objectType],
                              COLUMN_CODE.DEAL.NAME
                            )
                          ]
                        : rowData.metaData.recordsAfter?.[0]?.cells[
                            TableManger.getColumnId(
                              TABLES[rowData.objectType],
                              COLUMN_CODE.DEAL.NAME
                            )
                          ]}
                    </strong>
                    is updated.
                  </div>
                  <div>
                    Permission for team
                    <strong>
                      {rowData.metaData?.teamPermissionUpdates[0]?.team?.name}
                    </strong>
                    changed to&nbsp;
                    <strong>
                      {beforeData
                        ? PERMISSIONS[
                            rowData.metaData?.teamPermissionUpdates[0]
                              ?.accessLevelBefore
                          ].title
                        : PERMISSIONS[
                            rowData.metaData?.teamPermissionUpdates[0]
                              ?.accessLevelAfter
                          ].title}
                    </strong>
                    &nbsp;
                  </div>
                </div>
              </>
            );
        } else if (
          rowData.action ===
          NOTIFICATION_ACTION_TYPE.REQUIRED_COLUMN_SETTING_CHANGED
        ) {
          if (columnsMetaData?.length !== 0)
            return (
              <>
                <div style={{ textAlign: "left" }}>
                  {columnsMetaData?.map((columnData) =>
                    renderRowForRequiredFields(columnData)
                  )}
                </div>
              </>
            );
        } else if (
          [NOTIFICATION_ACTION_TYPE.REC_LINKED].includes(rowData.action)
        ) {
          return Renderers.linkedUnLinkedRenderer(rowData, !beforeData);
        } else if (
          [NOTIFICATION_ACTION_TYPE.REC_UNLINKED].includes(rowData.action)
        ) {
          return Renderers.linkedUnLinkedRenderer(rowData, beforeData);
        } else if (
          [
            NOTIFICATION_ACTION_TYPE.CRM3_RECORD_APPROVAL_REQUEST_CREATED,
            NOTIFICATION_ACTION_TYPE.CRM3_RECORD_APPROVAL_REQUEST_UPDATED,
            NOTIFICATION_ACTION_TYPE.CRM3_RECORD_APPROVAL_REQUEST_DELETED
          ].includes(rowData.action)
        ) {
          return Renderers.dealAutomationRenderer(rowData, beforeData);
        } else if (
          [
            NOTIFICATION_ACTION_TYPE.CRM3_RECORD_APPROVAL_REQUEST_CREATED,
            NOTIFICATION_ACTION_TYPE.CRM3_RECORD_APPROVAL_REQUEST_UPDATED,
            NOTIFICATION_ACTION_TYPE.CRM3_RECORD_APPROVAL_REQUEST_DELETED
          ].includes(rowData.action)
        ) {
          return Renderers.dealAutomationRenderer(rowData, !beforeData);
        } else if (
          [
            NOTIFICATION_ACTION_TYPE.NOTE_UPDATE,
            NOTIFICATION_ACTION_TYPE.ATTACHMENT_UPDATE,
            NOTIFICATION_ACTION_TYPE.REC_UPDATE
          ].includes(rowData.action) &&
          [OBJECT_TYPE.TICKET].includes(rowData.objectType)
        ) {
          if (
            Utility.isEmptyObject(rowData.metaData.recordsAfter) ||
            Utility.isEmptyObject(rowData.metaData.recordsBefore)
          )
            return "-";
          let arr = [];
          let primaryRecord = rowData.metaData.recordsAfter?.[0];
          let secondaryRecord = rowData.metaData.recordsBefore?.[0];
          if (beforeData) {
            primaryRecord = rowData.metaData.recordsBefore?.[0];
            secondaryRecord = rowData.metaData.recordsAfter?.[0];
          }
          for (const [key, value] of Object.entries(primaryRecord)) {
            if (
              secondaryRecord[key] !== value &&
              ![
                TICKET_FIELD_KEYS_WITH_DETAIL.CUSTOM_FIELD,
                TICKET_FIELD_KEYS_WITH_DETAIL.OTHER_REQUESTORS,
                TICKET_FIELD_KEYS_WITH_DETAIL.ASSIGNEE_DETAIL,
                TICKET_FIELD_KEYS_WITH_DETAIL.CREATED_BY_DETAIL,
                TICKET_FIELD_KEYS_WITH_DETAIL.UPDATED_BY_DETAIL,
                TICKET_FIELD_KEYS_WITH_DETAIL.CRM3_ACCOUNT_ID_DETAIL,
                TICKET_FIELD_KEYS_WITH_DETAIL.CRM3_CONTACT_ID_DETAIL,
                TICKET_FIELD_KEYS_WITH_DETAIL.OWNER_ID_DETAIL,
                TICKET_FIELD_KEYS_WITH_DETAIL.COMPONENT_ID_DETAIL,
                TICKET_FIELD_KEYS_WITH_DETAIL.RESOLVED_AT,
                TICKET_FIELD_KEYS_WITH_DETAIL.RESOLVED_BY,
                TICKET_FIELD_KEYS_WITH_DETAIL.RESOLVED_BY_DETAIL,
                TICKET_FIELD_KEYS_WITH_DETAIL.EMAIL,
                TICKET_FIELD_KEYS_WITH_DETAIL.UPDATED_BY,
                TICKET_FIELD_KEYS_WITH_DETAIL.OWNER_ID,
                TICKET_FIELD_KEYS_WITH_DETAIL.CLOSED_AT,
                TICKET_FIELD_KEYS_WITH_DETAIL.CLOSED_BY,
                TICKET_FIELD_KEYS_WITH_DETAIL.SRC_WF_ID,
                TICKET_FIELD_KEYS_WITH_DETAIL.SRC_WF_NODE_ID,
                TICKET_FIELD_KEYS_WITH_DETAIL.SRC_WF_NODE_EXEC_AUDIT_ID
              ].includes(key)
            ) {
              if ("c_field" === key) {
                arr = [...arr, ...getCFData(primaryRecord, secondaryRecord)];
              } else {
                arr.push({
                  key: getTicketKeyName(key),
                  value: getTicketValueData(key, value, primaryRecord)
                });
              }
            }
          }
          return arr?.map((columnData) => {
            return (
              <div style={{ textAlign: "left" }}>
                <b>{columnData?.key}</b>: {columnData.value}
              </div>
            );
          });
        } else if (
          [NOTIFICATION_ACTION_TYPE.REC_UPDATE].includes(rowData.action) &&
          [OBJECT_TYPE.QUOTE, OBJECT_TYPE.INVOICE].includes(rowData.objectType)
        ) {
          if (
            Utility.isEmptyObject(rowData.metaData.recordsAfter) ||
            Utility.isEmptyObject(rowData.metaData.recordsBefore)
          )
            return "-";
          let arr = [];
          let primaryRecord = rowData.metaData.recordsAfter?.[0];
          let secondaryRecord = rowData.metaData.recordsBefore?.[0];
          if (beforeData) {
            primaryRecord = rowData.metaData.recordsBefore?.[0];
            secondaryRecord = rowData.metaData.recordsAfter?.[0];
          }
          for (const [key, value] of Object.entries(primaryRecord)) {
            if (
              secondaryRecord[key] !== value &&
              Object.values(QUOTE_AUDIT_LOG_FIELDS).includes(key)
            ) {
              arr.push({
                key: getQuoteParsedKey(key),
                value: getQuoteParsedValue(key, value)
              });
            }
          }
          return arr?.map((columnData) => {
            return (
              <div
                className="text-align-left text-ellipsis"
                style={{
                  maxWidth: "100%"
                }}
              >
                <b>{columnData?.key}</b>: {columnData.value}
              </div>
            );
          });
        } else if (
          [NOTIFICATION_ACTION_TYPE.COLUMN_UPDATE].includes(rowData.action)
        ) {
          return Renderers.customFieldColumnRenderer(
            beforeData,
            rowData.metaData
          );
        }
      }
    } catch (error) {
      console.error("error:", error);
      return <div>-</div>;
    }
  };
  const showModulesList = () => {
    let allModules = [{ id: "", name: "All Modules" }];

    Object.entries(AUDIT_OBJECT_TYPE)
      ?.sort()
      ?.forEach((entry) => {
        allModules.push({ id: entry[0], name: entry[1] });
      });
    return (
      <DKListPicker2
        data={allModules}
        displayKey="name"
        className="z-index-5"
        allowSearch={true}
        searchableKey={"name"}
        onSelect={(index, obj) => {
          setShowAllModules(false);
          let updateFilter = { ...filter };
          updateFilter.moduleName = obj;
          setFilter(updateFilter);
        }}
        onClose={() => setShowAllModules(false)}
      />
    );
  };
  const showActionsList = () => {
    const allActions = [{ id: "", name: "All Actions " }];

    Object.entries(AUDIT_ACTIONS_FILTER)
      ?.sort()
      ?.forEach((entry) => allActions.push({ id: entry[0], name: entry[1] }));
    return (
      <DKListPicker2
        data={allActions}
        className="z-index-5"
        displayKey={"name"}
        allowSearch={true}
        searchableKey={"name"}
        onSelect={(index, obj) => {
          setShowAllActions(false);
          let updateFilter = { ...filter };
          updateFilter.actionType = obj;
          setFilter(updateFilter);
        }}
        onClose={() => setShowAllActions(false)}
      />
    );
  };
  const showUsersList = () => {
    const usersName = [];

    users.forEach((data) => {
      let obj = {
        name: `${data?.firstName} ${data?.lastName}`,
        id: data?.iamUserId,
        status: data?.status
      };
      if (data?.status === INVITE_USER_STATUS.JOINED) usersName.push(obj);
    });
    return (
      <DKListPicker2
        data={[{ id: "", name: "All Users", status: "" }, ...usersName]}
        displayKey="name"
        allowSearch={true}
        searchableKey={"name"}
        className="z-index-5"
        onSelect={(index, obj) => {
          setShowAllUsers(false);
          let updateFilter = { ...filter };
          updateFilter.users = obj;
          setFilter(updateFilter);
        }}
        onClose={() => setShowAllUsers(false)}
      />
    );
  };

  const getCFData = (primary, secondary) => {
    let primaryJson = JSON.parse(primary?.c_field);
    let secondaryJson = JSON.parse(secondary?.c_field);
    let arr = [];
    if (!Utility.isEmptyObject(primaryJson)) {
      for (const [key, value] of Object?.entries(primaryJson)) {
        if (value !== secondaryJson?.[key]) {
          arr.push({
            key,
            value: Utility.isEmptyObject(value)
              ? AUDIT_LOG_EMPTY_DISPLAY_VALUE
              : value
          });
        }
      }
    }
    return arr;
  };

  const getTicketValueData = (key, value, data) => {
    let keyData = TICKET_FIELDS.find((col) => col.id === key);
    let valueData = "";
    if (!Utility.isEmptyObject(keyData)) {
      valueData = value;
    } else {
      switch (key) {
        case TICKET_FIELD_KEYS_WITH_DETAIL.UPDATED_AT:
          valueData = `${DateUtil.getDateStrFromDate(
            new Date(value),
            DateUtil.getOrgDateFormat()
          )} ${Utility.getFormattedTime(new Date(value), true)}`;
          break;
        case TICKET_FIELD_KEYS_WITH_DETAIL.CRM3_CONTACT_ID:
          valueData =
            data[TICKET_FIELD_KEYS_WITH_DETAIL.CRM3_CONTACT_ID_DETAIL]?.cells[
              TableManger.getColumnId(TABLES.CONTACT, COLUMN_CODE.CONTACT.NAME)
            ] || AUDIT_LOG_EMPTY_DISPLAY_VALUE;
          break;
        case TICKET_FIELD_KEYS_WITH_DETAIL.CRM3_ACCOUNT_ID:
          valueData =
            data[TICKET_FIELD_KEYS_WITH_DETAIL.CRM3_ACCOUNT_ID_DETAIL]?.cells[
              TableManger.getColumnId(TABLES.ACCOUNT, COLUMN_CODE.ACCOUNT.NAME)
            ] || AUDIT_LOG_EMPTY_DISPLAY_VALUE;
          break;
        case TICKET_FIELD_KEYS_WITH_DETAIL.ASSIGNEE:
          valueData =
            data[TICKET_FIELD_KEYS_WITH_DETAIL.ASSIGNEE_DETAIL]?.name ||
            AUDIT_LOG_EMPTY_DISPLAY_VALUE;
          break;
        case TICKET_FIELD_KEYS_WITH_DETAIL.NOTE:
          let note = Utility.decodeBase64Uri(value);
          valueData = note
            ? `${note.substr(0, 45) ?? ""}${note?.length > 45 ? "..." : ""}`
            : "";
          break;
        case TICKET_FIELD_KEYS_WITH_DETAIL.TEAM_ID:
          let teamData = value?.map(
            (id) => teams?.find((team) => team?._id === id)?.name
          );
          valueData = teamData?.toString() ?? "NA";
          break;
        default:
          valueData = AUDIT_LOG_EMPTY_DISPLAY_VALUE;
          break;
      }
    }
    return valueData;
  };

  const getTicketKeyName = (key) => {
    let keyData = TICKET_FIELDS.find((col) => col.id === key);
    let keyName = "";
    if (!Utility.isEmptyObject(keyData)) {
      keyName = keyData?.name;
    } else {
      keyName = TICKET_FIELD_VISIBLE[key];
    }
    return keyName;
  };

  const getQuoteParsedValue = (key, value) => {
    try {
      if (typeof value === "boolean") {
        return value ? "Yes" : "No";
      }

      if (key === QUOTE_AUDIT_LOG_FIELDS.MULTI_APPROVAL_DETAILS) {
        value = value ? JSON.parse(value) : value;
        value = value?.currentLevel;
      }

      if (key === QUOTE_AUDIT_LOG_FIELDS.CUSTOM_FIELD) {
        value = value ? JSON.parse(value) : value;
        value = Array.isArray(value)
          ? value
              .map((cField) => `${cField.label}: ${cField.value || "-"}`)
              .join(", ")
          : "-";
      }

      if (key === QUOTE_AUDIT_LOG_FIELDS.ADDITIONAL_CHARGES) {
        value = value ? JSON.parse(value) : value;
        const discount =
          (value?.globalDiscount?.amount || 0) +
          (value?.globalDiscounts?.reduce(
            (total, discount) => total + (discount?.chargeAmount || 0),
            0
          ) || 0);
        value =
          typeof value === "object" ? (
            <>
              {value.additionalChargeAmount || 0} <br />
              <b>Global discount</b>: {discount}
            </>
          ) : (
            0
          );
      }

      if (key === QUOTE_AUDIT_LOG_FIELDS.EDITING_BY) {
        const user = getUserByUserId(value);
        value = user ? getFullName(user) : "NA";
      }

      if (key === QUOTE_AUDIT_LOG_FIELDS.EDITING_AT) {
        value = value ? getDateAsString(value, ACTIVITY_DATE_FORMAT) : "";
      }

      return value || "-";
    } catch (err) {
      return "-";
    }
  };

  const getQuoteParsedKey = (key) => {
    switch (key) {
      case QUOTE_AUDIT_LOG_FIELDS.MULTI_APPROVAL_DETAILS:
        return "Approval level";
      case QUOTE_AUDIT_LOG_FIELDS.LOCKED:
        return "Is locked";
      case QUOTE_AUDIT_LOG_FIELDS.EDITING_LOCKED:
        return "Editing locked";
      case QUOTE_AUDIT_LOG_FIELDS.EDITING_BY:
        return "Edit started by";
      case QUOTE_AUDIT_LOG_FIELDS.EDITING_AT:
        return "Edit started at";
      case QUOTE_AUDIT_LOG_FIELDS.DOCUMENT_CODE:
        return "Document number";
      case QUOTE_AUDIT_LOG_FIELDS.DOCUMENT_DATE:
        return "Document date";
      case QUOTE_AUDIT_LOG_FIELDS.SHIP_BY_DATE:
        return "Ship by";
      case QUOTE_AUDIT_LOG_FIELDS.VALID_TILL_DATE:
        return "Due date";
      default:
    }

    return getCapitalized(key.replaceAll("_", " "));
  };

  const getHeaderWithActions = () => {
    return (
      <div
        className="row row-responsive parent-width justify-content-between"
        style={{
          gap: 8
        }}
      >
        <div className="row width-auto">
          <DKLabel
            text={`${TABLE_DISPLAY_NAME[TABLES.AUDIT_LOG]} (${
              auditLogData?.totalCount || 0
            })`}
            className="fw-m fs-m"
          />
          {loading && <DKSpinner className={"ml-r"} />}
        </div>
        <div
          className="row row-responsive width-auto"
          style={{
            gap: 8
          }}
        >
          <div className="position-relative">
            <div
              className="bg-white border-m border-radius-m row width-auto cursor-hand p-h-m"
              style={{ height: 36 }}
              onClick={() => setShowAllModules(true)}
            >
              <DKLabel
                text={`Module name: ${filter?.moduleName?.name || "All"}`}
                className="text-ellipsis"
                style={{ minWidth: 100, maxWidth: 200, WebkitLineClamp: 1 }}
              />
              <DKIcon src={DKIcons.ic_arrow_down2} className={"ic-s ml-s"} />
            </div>
            <div
              className="position-absolute border-box shadow-m z-index-5 parent-width"
              style={{ top: 36, left: 0 }}
            >
              {showAllModules && showModulesList()}
            </div>
          </div>
          <div className="position-relative">
            <DKButton
              title={`Action type: ${filter.actionType?.name || "All"}`}
              className="border-m bg-white"
              icon={DKIcons.ic_arrow_down2}
              isReverse={true}
              onClick={() => setShowAllActions(true)}
            />
            <div
              className="position-absolute border-box shadow-m z-index-5"
              style={{ top: 36, left: 0 }}
            >
              {showAllActions && showActionsList()}
            </div>
          </div>
          <div className="position-relative">
            <DKButton
              title={`Action by: ${filter.users?.name || "All"}`}
              className="border-m bg-white"
              icon={DKIcons.ic_arrow_down2}
              isReverse={true}
              onClick={() => setShowAllUsers(true)}
            />
            <div
              className="position-absolute border-box border-m z-index-5"
              style={{ top: 36, left: 0 }}
            >
              {showAllUsers && showUsersList()}
            </div>
          </div>
          <div className="position-relative">
            <DKButton
              title={getDateRangeString()}
              className="border-m bg-white position-relative"
              icon={DKIcons.ic_calendar}
              onClick={() => setShowHideCalendar(true)}
            />
            <div
              className="position-absolute z-index-1 mobile-left-0"
              style={{ right: 630, top: 45 }}
            >
              {showHideCalendar && getDateRangePicker()}
            </div>
          </div>
          <DKSearchBar
            className="deal-kanban-search-bar"
            onSearch={onSearch}
            searchText={searchText}
          />
        </div>
      </div>
    );
  };
  const getDateRangeString = () => {
    if (!Utility.isEmptyObject(filterDates)) {
      return (
        DateUtil.getDateStrFromDate(
          filterDates.financialStartDate,
          DateUtil.getOrgDateFormat()
        ) +
        " to " +
        DateUtil.getDateStrFromDate(
          filterDates.financialEndDate,
          DateUtil.getOrgDateFormat()
        )
      );
    } else {
      return "";
    }
  };
  const getDateRangePicker = () => {
    return (
      <div className="position-absolute shadow-m bg-white z-10 top-12 right-20">
        <DKDateRangePicker
          className="border shadow "
          onClose={() => {
            setShowHideCalendar(false);
          }}
          color={"rgb(101,24,102)"}
          showPresetList={true}
          startDate={filterDates.financialStartDate}
          selectedStartDate={filterDates.financialStartDate}
          selectedEndDate={filterDates.financialEndDate}
          onSelectDateRange={(startDate: Date, endDate: Date) => {
            if (startDate && endDate) {
              setFilterDates({
                financialStartDate: startDate,
                financialEndDate: endDate
              });
              setShowHideCalendar(false);
              let updateFilter = { ...filter };
              updateFilter.fromDate = startDate;
              updateFilter.toDate = new Date(
                new Date(endDate).setDate(endDate.getDate() + 1)
              );
              setFilter(updateFilter);
            }
          }}
        />
      </div>
    );
  };

  return (
    <div
      className="column parent-width flex-1"
      style={{
        paddingBottom: props.recordId ? 60 : 0 /* for accomodating chat icon */
      }}
    >
      <div className="column mt-s parent-width mb-s position-relative flex-1 align-items-center-mobile">
        <Suspense
          fallback={
            <div className="row justify-content-center">
              <DKSpinner />
            </div>
          }
        >
          {getHeaderWithActions()}
          {auditLogData?.data.length === 0 && getNoDataView()}
          <DKDataGrid
            allowColumnAdd={false}
            width={
              SideBarService.getContainerWidth() -
              (props?.recordId && !(isMobileAppWebView() || isViewportMobile())
                ? DETAIL_PAGE_LEFT_PANEL_WIDTH - 100
                : 0)
            }
            gridStickyHeaderConfig={
              isMobileAppWebView()
                ? null
                : {
                    getRootScrollContainer: () =>
                      document.getElementById(LOG_SCROLL_CONTAINER_ID)
                  }
            }
            allowColumnEdit={false}
            allowColumnDelete={false}
            allowColumnShift={false}
            allowColumnSort={false}
            currentPage={auditLogData?.pageNo}
            columns={columns}
            allowRowAdd={false}
            allowRowEdit={false}
            allowSearch={false}
            allowBulkOperation={false}
            rows={
              auditLogData?.data
                ? JSON.parse(JSON.stringify(auditLogData.data))
                : []
            }
            tableName={TABLES.AUDIT_LOG}
            needTrailingColumn={true}
            totalPageCount={Math.ceil(
              auditLogData?.totalCount / auditLogData?.pageSize
            )}
            onPageChange={onPageChange}
            dateFormat={convertCRMDateFormatToUILibraryFormat(
              DateUtil.getOrgDateFormat()
            )}
          />
        </Suspense>
      </div>
    </div>
  );
}
